import { getRequest, postRequest, downloadRequest } from "@/libs/axios";

// ------------------------日志管理-----------------------
// 用户登录日志--列表
export function userLoginList(params) {
    return getRequest("/yethan/log/userLogin/listPage", params);
}
// 用户登录日志--导出
export function userLoginListExport(params) {
    return downloadRequest("/yethan/log/userLogin/exportExcel", params);
}
// 用户操作日志--列表
export function userOperationList(params) {
    return getRequest("/yethan/log/userOperation/listPage", params);
}
// 用户操作日志--导出
export function userOperationListExport(params) {
    return downloadRequest("/yethan/log/userOperation/exportExcel", params);
}
// 报名审核日志--列表
export function enrollCheckList(params) {
    return getRequest("/yethan/log/enrollCheck/listPage", params);
}
// 报名审核日志--导出
export function enrollCheckListExport(params) {
    return downloadRequest("/yethan/log/enrollCheck/exportExcel", params);
}
// 成绩管理日志--列表
export function scoreSetList(params) {
    return getRequest("/yethan/log/scoreSet/listPage", params);
}
// 成绩管理日志--导出
export function scoreSetListExport(params) {
    return downloadRequest("/yethan/log/scoreSet/exportExcel", params);
}
// 数据库备份日志--列表
export function backupDbList(params) {
    return getRequest("/yethan/log/backupDb/listPage", params);
}
// 数据库备份日志--导出
export function backupDbListExport(params) {
    return downloadRequest("/yethan/log/backupDb/exportExcel", params);
}
// 数据库--备份
export function backupDb(params) {
    return getRequest("/yethan/log/backupDb", params);
}
// 数据库表备份日志--列表
export function backupTableList(params) {
    return getRequest("/yethan/log/backupTable/listPage", params);
}
// 数据库表备份日志--导出
export function backupTableListExport(params) {
    return downloadRequest("/yethan/log/backupTable/exportExcel", params);
}
// 数据库表名--列表
export function tableList(params) {
    return getRequest("/yethan/log/backupTable/tableListPage", params);
}
// 数据库表--备份
export function backupDbTable(params) {
    return postRequest("/yethan/log/backupDbTable", params);
}
